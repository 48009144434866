<div class="wrap-modal" (click)="closeModal($event)">
    <!-- Modal content -->
    <div class="modal-content">
        <div class="modal-header">
            <h2>{{alert.title | translate}}</h2>
        </div>
        <div class="modal-body">
            <p *ngIf="alert.message">{{alert.message | translate}}</p>
            <div *ngIf="alert?.messages?.length > 0">
                <p style="font-size: 1.6rem;color:#3B4043;" *ngFor="let item of alert?.messages">{{item | translate}}</p>
            </div>

            <div class="image" *ngIf="alert['image']">
                <img src="{{alert['image']}}" alt="im">
            </div>
            <div class="form" *ngIf="show_input">
                <input (keyup)="key(text.value)" #text type="text">
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn-top button-linear-pink btn-radius" *ngIf="alert.mode == CrudType.HOME"
                routerLinkActive="router-link-active" (click)="close()">
                TOPへ
            </button>
            <button class="btn-top button-linear-pink btn-radius" *ngIf="alert.mode == CrudType.CLOSE"
                (click)="close()">
                {{ 'UTILS.CLOSE' | translate }}
            </button>
            <button class="btn-top button-linear-pink btn-radius" *ngIf="alert.mode ==CrudType.CONFIRM"
                (click)="close()">
                {{ 'UTILS.CANCEL' | translate }}
            </button>
            <button class="btn-top button-linear-pink btn-radius"
                *ngIf="alert.mode == CrudType.SAVE || alert.mode == CrudType.CONFIRM" (click)="save()">
                {{ 'UTILS.SAVE' | translate }}
            </button>
            <button class="btn-top button-linear-pink btn-radius" *ngIf="alert.mode == CrudType.SUCCESS"
                (click)="success()">
                {{ 'UTILS.OK' | translate }}
            </button>
            <button class="btn-top button-linear-pink btn-radius" *ngIf="alert.mode == CrudType.OPTION"
            (click)="save()">
            {{ alert.textbtn | translate }}
        </button>
        </div>
        <img src="assets/images/icon/ic_plus.svg" *ngIf="alert?.is_close"  alt="delete" class="delete" (click)="close()">
    </div>

</div>