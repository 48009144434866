<div class="header" [ngClass]="{'bglinear': bglinear}">
  <div class="inner-header">
    <div class="bra">
      <a routerLink="/">
        Link Japan
      </a>
      <span *ngIf="bglinear"> {{ 'HEADER.TO_REGISTER' | translate }}</span>
    </div>

    <span class="bar" (click)="toogleBar()" *ngIf="!bglinear">
      <i class='fas fa-bars' style='font-size:24px'></i>
    </span>
    <ul class="header-nav" id="nav-mobile" *ngIf="!bglinear">
      <li>
        <a (click)="toogle('top')" [ngClass]="{'active': fragment == 'top' || !fragment }" routerLink="/"
          fragment="top">TOP</a>
      </li>
      <li>
        <a (click)="toogle('introduce')" [ngClass]="{'active': fragment == 'introduce'  }" routerLink="/"
          fragment="introduce">{{ 'HEADER.TO_SUPPORT' | translate }}</a>
      </li>
      <li>
        <a (click)="toogle('qa')" [ngClass]="{'active': fragment == 'qa' }" routerLink="/" fragment="qa">{{
          'HEADER.TO_QA' | translate }}</a>
      </li>
      <li>
        <a (click)="toogle('enrollment')" [ngClass]="{'active': fragment == 'enrollment'  }" routerLink="/"
          fragment="enrollment">{{ 'HEADER.TO_REGISTER_DETAIL' | translate }}</a>
      </li>
      <li>
        <a (click)="toogle('contact')" routerLink="/contact" [ngClass]="{'active': fragment == 'contact'  }">{{
          'HEADER.TO_CONTACT_US' | translate }}</a>
      </li>
      <li>
        <div class="dropdown">
          <a class="selected" (click)="isShowDropdownLang = !isShowDropdownLang">{{lang}}</a>
          <div class="dropdown-content" *ngIf="isShowDropdownLang">
            <p class="dropdown-item" *ngFor="let item of languages"
              [ngClass]="{active: item.isActive}" (click)="changeLang(item)"
            >{{item.itemName}}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>