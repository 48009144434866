import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../shared/services/helpers/base.service';

@Injectable({ providedIn: 'root' })
export class AdminService extends BaseService {

    constructor(protected http: HttpClient) {
        super(http);
    }

    getUsers(page, limit) {
        return this.getData(`admin/user?page=${page}&limit=${limit}`);
    }

    searchUsers(searchKey, page, limit) {
        return this.getData(`admin/user?search=${searchKey}&page=${page}&limit=${limit}`);
    }

    getUserDetail(id) {
        return this.getData(`admin/user/${id}`);
    }

    updateTypeUser(id, type) {
        return this.putData(`admin/user/${id}`, type);
    }

    getPayments(page, limit) {
        return this.getData(`admin/payment?page=${page}&limit=${limit}`);
    }


    getContacts(page, limit) {
        return this.getData(`admin/contact?page=${page}&limit=${limit}`);
    }

    login(path, auth) {
        return this.postData(`login/${path}`, null, [{ key: 'auth', value: auth }]);
    }

    postRoles(id, body) {
        return this.putData(`admin/user/${id}`, body)
    }

    // Conversation 
    getConversations(page, limit = 12, admin_id = "") {
        if (admin_id) return this.getData(`admin/conversations?admin_id=${admin_id}&page=${page}&limit=${limit}`);
        else return this.getData(`admin/conversations?page=${page}&limit=${limit}`)
    }

    getConversation(conversation_id, limit, page) {
        return this.getData(`admin/conversation/${conversation_id}?limit=${limit}&page=${page}`);
    }

    getCommunity() {
        return this.getData(`country`);
    }

    getComunityDetail(country_id) {
        return this.getData(`country/${country_id}`)
    }

    createCommunity(body) {
        return this.postData(`admin/country`, body);
    }

    updateCommunity(id, body) {
        return this.putData(`admin/country/${id}`, body);
    }

    getProfileAdmin() {
        return this.getData(`profile/info`);
    }

    getWork(type, page, limit) {
        return this.getData(`admin/work-list/${type}?page=${page}&limit=${limit}`);
    }

    searchWork(type, searchKey, page, limit) {
        return this.getData(`admin/work-list/${type}?search=${searchKey}&page=${page}&limit=${limit}`);
    }

    searchWorkRefineByUserID(type, searchKey, page, limit, user_id) {
        return this.getData(`admin/work-list/${type}?search=${searchKey}&page=${page}&limit=${limit}&user_id=${user_id}`);
    }

    getWorkRefineByUserID(type, page, limit, user_id) {
        return this.getData(`admin/work-list/${type}?page=${page}&limit=${limit}&user_id=${user_id}`);
    }

    getWorkDetail(id) {
        return this.getData(`admin/work/${id}`);
    }

    createWork(body) {
        return this.postData(`admin/work`, body);
    }


    updateWork(id, body) {
        return this.putData(`admin/work/${id}`, body);
    }

    getStation() {
        return this.getData(`admin/station`);
    }

    getRegal(page, limit) {
        return this.getData(`admin/regal?page=${page}&limit=${limit}`);
    }

    getRegalDetail(id) {
        return this.getData(`admin/regal/${id}`);
    }

    createRegal(body) {
        return this.postData(`admin/regal`, body);
    }

    updateRegal(id, body) {
        return this.putData(`admin/regal/${id}`, body)
    }

    deleteRegal(id) {
        return this.delete(`admin/regal/${id}`);
    }

    // Regal chat
    getConversationsRegal(page, limit = 12) {
        return this.getData(`admin/regal/comunity?page=${page}&limit=${limit}`);
    }

    getConversationRegal(conversation_id, limit, page) {
        return this.getData(`regal/comunity?conversation=${conversation_id}&limit=${limit}&page=${page}`);
    }

    // Reception
    getReceptions(searchKey) {
        return this.getData(`news/reception?search=${searchKey}`);
    }

    addNews(new_id, country_id) {
        return this.postData(`admin/news-history`, {
            new_id: new_id,
            country_id: country_id
        });
    }

    deleteNews(new_id) {
        return this.delete(`admin/news-history/${new_id}`)
    }
}
