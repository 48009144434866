import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SocketMessageService } from './shared/services/user/socket-message.service';
import { UserService } from './shared/services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private socketMessageService: SocketMessageService
  ) {
  }

  ngOnInit(): void {
    let lang = localStorage.getItem('locale') || 'idn';
    this.translateService.setDefaultLang(lang);
    localStorage.setItem("locale",lang);
    this.userService._loggedIn.subscribe(
      res => {
        if (res) {
          this.userService.getProfile();         
        }
        else {
          if (this.socketMessageService.socket) {
            this.socketMessageService.disconnectSocket();
          }
        }
      }
    )

    this.userService._profileChanged.subscribe(
      res => {
        if (res) {
          if (!this.socketMessageService.socket) {
            this.socketMessageService.connect(res._id);
            this.socketMessageService.listenNotifyJoin();
            this.socketMessageService.listenNotifyMessage();
            this.socketMessageService.listenConversationAdmin();
            this.socketMessageService.listenNotifyJoinRegal();
            this.socketMessageService.listenConversationAdminRegal();
          }
        }
      }
    )

  }

}
