import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AdminService } from 'src/app/admin/admin.service';
import { environment as config } from 'src/environments/environment';
import { ComponentActions } from '../../components/alert/component-actions';
import { SocketMessageService } from '../../services/user/socket-message.service';
import { StorageService } from '../../services/user/storageService';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  admin_id = "";
  listMessage = [];
  info;
  lang = 'JAPAN';
  optionsLang = [
    {
      itemName: 'JAPAN',
      lang: 'ja'
    },
    {
      itemName: 'ENGLISH',
      lang: 'en'
    }
  ];
  menus = [
    {
      id: 1,
      title: 'NAV.ARTICAL_POSTING',
      image: 'assets/images/icon/ic_art_post.svg',
      url: `/${config.routerLoginAdmin}`,
      isShow: true
    },
    {
      id: 7,
      title: 'NAV.USER_LIST',
      image: 'assets/images/icon/ic_user_list.svg',
      url: `/${config.routerLoginAdmin}/user`,
      isShow: true
    },
    {
      id: 8,
      title: 'NAV.PAYMENT_LIST',
      image: 'assets/images/icon/ic_payment_list.svg',
      url: `/${config.routerLoginAdmin}/payment`,
      isShow: true
    },
    {
      id: 2,
      title: 'NAV.PROFESSION',
      image: 'assets/images/icon/ic_pro.svg',
      url: `/${config.routerLoginAdmin}/profession`,
      isShow: true
    },
    {
      id: 3,
      title: 'NAV.REAL_ESTATE',
      image: 'assets/images/icon/ic_real_estate.svg',
      url: `/${config.routerLoginAdmin}/real-estate`,
      isShow: true
    },
    {
      id: 4,
      title: 'NAV.TRAVEL_TOURISM',
      image: 'assets/images/icon/ic_travel_tour.svg',
      url: `/${config.routerLoginAdmin}/travel-tourism`,
      isShow: true
    },
    {
      id: 5,
      title: 'NAV.RESTAURANT',
      image: 'assets/images/icon/ic_restaurant.svg',
      url: `/${config.routerLoginAdmin}/restaurant`,
      isShow: true
    },
    {
      id: 6,
      title: 'NAV.HAIR_DRESSING_SHOP',
      image: 'assets/images/icon/ic_hair_dress.svg',
      url: `/${config.routerLoginAdmin}/hair-dressing-shop`,
      isShow: true
    },
    {
      id: 9,
      title: 'NAV.LIST_OF_LEGAL',
      image: 'assets/images/icon/ic_list_legal.svg',
      url: `/${config.routerLoginAdmin}/legal`,
      isShow: true
    },
    {
      id: 13,
      title: 'NAV.LIST_MSG_LEGAL',
      image: 'assets/images/icon/ic_list_legal.svg',
      url: `/${config.routerLoginAdmin}/legal-chat`,
      isShow: true,
      notification: 0,
    },
    {
      id: 10,
      title: 'NAV.COMMUNITY_LIST',
      image: 'assets/images/icon/ic_com_list.svg',
      url: `/${config.routerLoginAdmin}/community`,
      isShow: true
    },
    {
      id: 12,
      title: 'NAV.INQUIRY_CHAT',
      image: 'assets/images/icon/ic_inq_chart.svg',
      url: `/${config.routerLoginAdmin}/chats`,
      notification: 0,
      isShow: true
    },
    {
      id: 11,
      title: 'NAV.SETTING',
      image: 'assets/images/icon/ic_settings.svg',
      url: `/${config.routerLoginAdmin}/settings`,
      isShow: true
    },
  ];
  cloneMenu;
  logined = false;
  isOpenSubs = false;
  isEnabled = false;
  subject_profile;
  avatar;
  hideNavbar = false;
  configs

  constructor(
    private userService: UserService,
    private socketMessageService: SocketMessageService,
    private storageService: StorageService,
    private router: Router,
    private adminService: AdminService,
    private componentAction: ComponentActions
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let pathname = window.location.pathname;
        this.hideNavbar = ((pathname === "/") || (pathname.includes("/work-detail"))) ? true : false;
      }
    });
  }

  ngOnInit(): void {
    this.configs = `/${config.routerLoginAdmin}`;
    let lang = localStorage.getItem('locale');
    this.optionsLang.forEach(item => {
      if (item.lang === lang) {
        this.lang = item.itemName;
      }
    });
    this.userService._loggedIn.subscribe(
      res => {
        this.logined = res;
        if (res) {
          this.getMessageUnseen();
          this.getMessageUnseenRegal();
          const roles = JSON.parse(this.userService.getRoles());
          if (roles?.length <= 0 || !roles) {
            return
          }
          roles.push(11, 12);
          this.cloneMenu = this.menus;
          this.menus = this.cloneMenu.map((element, i) => {
            return roles?.includes(element.id) ? element : null;
          }).filter(o => o != null);
        }
      }
    )
    this.subject_profile = this.userService._profileChanged.subscribe(
      res => {
        if (res) {
          this.info = res;
          this.getMenu(res._id);
          this.avatar = config.host + "/" + res.url_avatar;
        }
      }
    );
    this.socketMessageService._messageNotify.subscribe(
      res => {
        this.getMessageUnseen();
        if (res) {
          if (res['user'] && res['user']['role'] == 0) {
            // this.isNotifications++;
          }
        }
      }
    )
    this.socketMessageService._messageNotifyRegal.subscribe(
      res => {
        this.getMessageUnseenRegal();
      }
    )
    this.socketMessageService.getHasMessageSeen().subscribe(data => {
      this.menus.forEach(menu => {
        if (menu?.id == 12) {
          menu.notification = menu.notification - 1;
        }
      });
    })
    this.socketMessageService.getHasMessageSeenRegal().subscribe(data => {
      if (data == 'add') {
        this.menus[9].notification = this.menus[9].notification + 1;
      } else {
        if (this.menus[9].notification > 0) {
          this.menus[9].notification = this.menus[9].notification - 1;
        }
      }
    })
  }

  getMessageUnseen() {
    this.adminService.getProfileAdmin().subscribe(data => {
      this.admin_id = data._id;
      this.adminService.getUserDetail(this.admin_id).subscribe(res => {
        if (res.roles?.length > 0) {
          this.getConversations(this.admin_id);
        } else {
          this.getConversations();
        }
      }, err => {
        console.log(err);
      })
    })
  }

  getConversations(id = "") {
    this.adminService.getConversations(1, 500, id).subscribe(data => {
      this.listMessage = data.docs;
      let countUnssen = 0;
      data.docs.forEach(element => {
        if (element.seen === 0) countUnssen++;
      });
      this.menus.forEach(menu => {
        if (menu?.id === 12) {
          menu.notification = countUnssen;
        }
      });
    }, err => {
      console.log(err);
    })
  }

  getMessageUnseenRegal() {
    this.adminService.getConversationsRegal(1, 50).subscribe(data => {
      let countUnssenRegal = 0;
      data.docs.forEach(element => {
        if (element.created_at > element.admin_seen_at) {
          countUnssenRegal++;
          this.socketMessageService.arrRegal.push(element._id);
        };
      });
      if (this.menus[9]) {
        this.menus[9].notification = countUnssenRegal;
      }
    }, err => {
      console.log(err);
    })
  }

  getMenu(user_id) {
    this.adminService.getUserDetail(user_id).subscribe(
      res => {
        if (res) {
          // this.userService.rolesChanged.next(res);
          let menu = res.roles;
          if (menu?.length <= 0 || !menu) {
            return
          }
          menu.push(11, 12);
          this.menus = this.cloneMenu.map((element, i) =>
            menu?.includes(element.id) ? element : null).filter((v) => v !== null);
          this.router.navigateByUrl(`${this.menus[0].url}`)
        }
      },
      err => {
        console.log(err);
      }
    )
  }

  openSubs(id) {
    if (id == 4) {
      this.isOpenSubs = !this.isOpenSubs;
    }
    let nav = document.getElementsByClassName('nav');
    nav[0].classList.toggle('active');
  }

  handleType(id) {
    this.socketMessageService.changeRefineByType(id);
  }

  setLang(evt) {
    this.lang = evt.value.itemName;
    this.storageService.setLang(evt.value.lang);
  }

  toggleMenu() {
    let nav = document.getElementsByClassName('nav');
    nav[0].classList.toggle('active');
  }

  navigatSetting() {
    this.router.navigate([`${config.routerLoginAdmin}/settings`]);
  }

  ngOnDestroy() {
    if (this.subject_profile) {
      this.subject_profile.unsubscribe();
    }
  }
}
