export enum CrudType {
   CLOSE = 1,
   SAVE = 2,
   CONFIRM = 3,
   SUCCESS = 4,
   HOME = 5,
   OPTION = 6
}
export enum TYPEWORK {
   JOB = 2,
   HOUSING = 3,
   TRAVERING = 4,
   GOURMET = 5,
   BEAUTY=  6
}