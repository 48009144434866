import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment as config, environment } from 'src/environments/environment';
import * as io from 'socket.io-client';
import { BaseService } from '../helpers/base.service';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helpers/helper.service';

@Injectable({ providedIn: 'root' })
export class SocketMessageService extends BaseService {
    public socket;

    numberMessages = new BehaviorSubject(null);
    _numberMessages = this.numberMessages.asObservable();

    subject_connect = new Subject();
    _subject_connect = this.subject_connect.asObservable();

    messageNotify = new Subject();
    _messageNotify = this.messageNotify.asObservable();

    messageNotifyRegal = new Subject();
    _messageNotifyRegal = this.messageNotifyRegal.asObservable();

    refineByType = new Subject();
    _refineByType = this.refineByType.asObservable();

    haveMessageSeen = new Subject();

    haveMessageSeenRegal = new Subject();

    total = 0;
    totalRegal = 0;
    arr = [];
    arrRegal = [];
    constructor(
        private helperService: HelperService,
        public http: HttpClient
    ) {
        super(http);
    }

    connect(user_id) {
        let selt = this;
        if (this.socket) {
            selt.subject_connect.next(true);
        }
        if (!user_id) {
            return;
        }
        if(config.production){
            this.socket = io(`${config.hostWeb}`, {path: "/socket/socket.io"});
        }else{
            this.socket = io(config.host);
        }        // this.socket = io(config.host);
        this.socket.on('connect', (e) => {
            console.log("connected");

            this.socket.emit("link:user:join", {
                user_id: user_id
            });
            selt.subject_connect.next(true);
        });
        this.socket.on('disconnected', (e) => {
            console.log("connected11111");
        });
    }

    changeRefineByType(id) {
        this.refineByType.next(id);
    }

    error() {
        return Observable.create(observer => {
            this.socket.on('connect_error', (err) => {
                // observer.next(err);
                console.log(err);
            })
        })
    }

    listenNotifyJoin() {
        this.socket.on("link:user:join", (r) => {
            this.numberMessages.next(r.unseen);
        });
    }

    listenNotifyMessage() {
        this.socket.on('link:user:inbox', (r) => {
            console.log(r);

            this.numberMessages.next(r.unseen);
        })
    }

    listenConversationAdmin() {
        this.socket.on('link:conversation:admin', (r) => {
            this.total++;
            this.messageNotify.next(r)
        })
    }

    subtractNotify(id) {
        let boolean = this.arr.findIndex(e => e == id);
        if (boolean >= 0) {
            return;
        } else {
            this.total--;
            this.messageNotify.next(this.total);
            this.arr.push(id)
        }
    }

    // Conversation 
    joinConversation(conversationid) {
        this.socket.emit('link:conversation:join', {
            conversation_id: conversationid
        })
    }

    listenConversation() {
        return Observable.create(observer => {
            this.socket.on('link:conversation:join', (data) => {
                observer.next(data);
            })
        });
    }

    sendMessage(key, message) {
        if (this.socket) {
            this.socket.emit('link:conversation:message', {
                authen: key,
                message: message,
                type: 1
            });
        }
    }

    listenMessageConversation() {
        return Observable.create(observer => {
            this.socket.on('link:conversation:message', (data) => {
                observer.next(data);
                // this.countMessage(data.unseen)
            })
        })
    }

    listenMessageUnseen(key) {
        this.socket.emit('link:conversation:seen', {
            authen: key,
        })
    }

    hasMessageSeen() {
        this.haveMessageSeen.next(1);
    }

    getHasMessageSeen() {
        return this.haveMessageSeen;
    }
    // end Conversation
    // number of notify that unseen
    countMessage(value) {
        this.numberMessages.next(value);
    }

    // Regal Chat
    joinConversationRegal(conversationid) {
        this.socket.emit('link:regal:join', {
            conversation: conversationid
        })
    }

    listenConversationRegal() {
        return Observable.create(observer => {
            this.socket.on('link:regal:join', (data) => {
                observer.next(data);
            })
        });
    }

    sendMessageRegal(key, message) {
        if (this.socket) {
            this.socket.emit('link:regal:message', {
                authen: key,
                message: message,
                type: 1
            });
        }
    }

    listenMessageConversationRegal() {
        return Observable.create(observer => {
            this.socket.on('link:regal:message', (data) => {
                observer.next(data);
                // this.countMessage(data.unseen)
            })
        })
    }

    listenMessageUnseenRegal(key) {
        this.socket.emit('link:regal:seen', {
            authen: key,
        })
        this.socket.on('link:regal:seen', (data) => {
            // console.log(data);
        })
    }

    hasMessageSeenRegal(type) {
        if (type === 'add') {
            this.haveMessageSeenRegal.next(type);
        } else {
            this.haveMessageSeenRegal.next(1);
        }
    }

    getHasMessageSeenRegal() {
        return this.haveMessageSeenRegal;
    }

    listenNotifyJoinRegal() {
        this.socket.emit("link:regal:join-list", (r) => {
            // this.numberMessages.next(r.unseen);
        });
    }

    listenConversationAdminRegal() {
        this.socket.on('link:regal:list', (r) => {
            this.messageNotifyRegal.next(r)
        })
    }

    addNotifyRegal(id) {
        let boolean = this.arrRegal.findIndex(e => e == id);
        if (boolean >= 0) {
            return;
        } else {
            this.hasMessageSeenRegal('add');
            this.arrRegal.push(id)
        }
    }
    // End Regal Chat
    disconnectSocket() {
        console.log("disconnected");

        this.socket.disconnect();
    }

}