import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isNewMember = true;
  constructor(private router: Router) {
  
  }

  ngOnInit() {
    this.isNewMember = location.pathname != '/auth/register';
    this.router.events.subscribe(
      params => {
        if (params instanceof NavigationEnd) {
          this.isNewMember = location.pathname != '/auth/register';
        }
      }
    );
  }

}
