import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent implements OnInit {
  @Input() options: {}[];
  @Input() nameSelect: string;
  @Output() selectedOption = new EventEmitter();
  @Input() firstSelect = false;
  isOpen = false;
  abv = false;
  constructor() { }

  ngOnInit() {
  }

  handleSelectOption(value) {
    this.selectedOption.emit({ value: value });
    if (!this.firstSelect) {
      this.nameSelect = value.itemName;
    }
    this.openDropdown();
  }

  openDropdown() {
    this.isOpen = !this.isOpen;
  }

  closeDropDown() {
    this.isOpen = false
  }

  run(valu){
  }
}
