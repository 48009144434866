<div #myModal id="modal" class="modal">
    <div class="wrap">
        <div class="content" [formGroup]="formRoles">
            <div class="content__main">
                <label formArrayName="orders" *ngFor="let order of ordersFormArray.controls; let i = index">
                    <input type="checkbox" [formControlName]="i">
                    {{ordersData[i]?.name}}
                </label>
            </div>
            <button (click)="submit()" class="btn-top">Submit</button>
            <i class="fas fa-times" (click)="close()"></i>
        </div>
    </div>
</div>
