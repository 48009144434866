
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AdminService } from 'src/app/admin/admin.service';
import { TYPEWORK } from 'src/app/shared/enums/crud-type.enum';
import { ComponentActions } from '../component-actions';

@Component({
  selector: 'app-popup-roles',
  templateUrl: './popup-roles.component.html',
  styleUrls: ['./popup-roles.component.scss']
})
export class PopupRolesComponent implements OnInit {

  @ViewChild('myModal', {static: false}) modal : ElementRef;
  @Input() user_info;
  formRoles: FormGroup;
  ordersData = [
    { id: TYPEWORK.JOB, name: 'Job',checked: false },
    { id: TYPEWORK.BEAUTY, name: 'Beauty',checked: false },
    { id: TYPEWORK.GOURMET, name: 'Gourmet',checked: false },
    { id: TYPEWORK.HOUSING, name: 'Housing',checked: false },
    { id: TYPEWORK.TRAVERING, name: 'Travering',checked: false },
  ];

  get ordersFormArray() {
    return this.formRoles?.controls.orders as FormArray;
  }

  constructor(private formBuilder: FormBuilder,
    private componentAction: ComponentActions,
    private adminService: AdminService) {
  }

  ngOnInit(): void {
    this.formInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.user_info?.currentValue) {
      let arrRoles = changes.user_info.currentValue?.roles;
      let arr = this.ordersData.map((e,i) => {
        if(arrRoles?.includes(e.id)){
          this.ordersData[i].checked = true;
        }
        return this.ordersData[i]
      });
      this.ordersData = arr;
      this.addCheckboxes();
    }
  }

  formInit() {
    this.formRoles = this.formBuilder.group({
      orders: new FormArray([])
    })
  }

  addCheckboxes() {
    this.ordersData.forEach((e) => this.ordersFormArray?.push(new FormControl(e.checked)));
  }

  open() {
    this.modal.nativeElement.style.display = 'block';
  }

  close() {
    this.modal.nativeElement.style.display = 'none';
  }

  submit() {
    if(this.formRoles.valid && this.formRoles.dirty) {
      this.componentAction.showLoading();
      const selectedOrderIds = this.formRoles.value.orders
        .map((checked, i) => checked ? this.ordersData[i].id : null)
        .filter(v => v !== null);
      const body = {
        role : 1,
        roles: selectedOrderIds
      }
    
      this.adminService.postRoles(this.user_info?._id,body).subscribe(
        res => {         
          this.componentAction.hideLoading();
        },
        err => {
          this.componentAction.hideLoading();
        }
      )     
    }
    this.modal.nativeElement.style.display = 'none';
  }

}
