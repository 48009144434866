import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimaryLayoutComponent } from './primary-layout/primary-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';

import { RouterModule } from '@angular/router';
import { FooterComponent } from '../core/footer/footer.component';
import { HeaderComponent } from '../core/header/header.component';
import { SideNavComponent } from '../core/side-nav/side-nav.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { SelectDropdDownModule } from '../components/select-dropdown/select-drop.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild(),
        SelectDropdDownModule
    ],
    declarations: [
        PrimaryLayoutComponent,
        MainLayoutComponent,
        FooterComponent,
        HeaderComponent,
        SideNavComponent,
        AdminLayoutComponent
    ],
    exports: [
        PrimaryLayoutComponent,
        MainLayoutComponent,
        FooterComponent,
        HeaderComponent,
        SideNavComponent,
        AdminLayoutComponent
    ]
})
export class LayoutModule { }
