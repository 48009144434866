import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
// const AUTHEN_TOKEN = 'authen_token';
const USER_INFO = 'data';
const locale = 'locale';
const TOKEN = 'token';
const ROLES = 'roles';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    lang = new Subject();
    _lang = this.lang.asObservable();

    constructor(
        private translateService: TranslateService,
    ) { }

    setLang(lang) {
        this.lang.next(lang);
        this.translateService.setDefaultLang(lang);
        return localStorage.setItem(locale, lang);
    }
    getLang() {
        return localStorage.getItem(locale);
    }

    setToken(value) {
        localStorage.setItem(TOKEN, value);
    }

    getToken(){
        return localStorage.getItem(TOKEN);

    }
    removeToken() {
        return localStorage.removeItem(TOKEN);
    }

    setRoles(value) {
        localStorage.setItem(ROLES, value);
    }

    getRoles(){
        return localStorage.getItem(ROLES);

    }
    removeRoles() {
        return localStorage.removeItem(ROLES);
    }
}
