import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  @Input() images
  @Output() isCloseGallery = new EventEmitter();

  constructor() { }

  ngOnInit(): void {}

  changeMainImgGallery(item){
    document.getElementById('gallery--main__img').setAttribute("src", item);
  }

  closeGallery(){
    this.isCloseGallery.emit(true);
  }

}
