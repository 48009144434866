<div class="popopConfirm" *ngIf="isConfirmDelete">
    <div class="wrap-content">
        <h3>{{ title }}</h3>
        <div class="buttons">
            <button class="btn button-linear-pink" (click)="confirmDelete()">
                Yes
            </button>
            <button class="btn button-linear-pink" (click)="cancelDelete()">
                No
            </button>
        </div>
    </div>
</div>
