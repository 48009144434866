import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertConfirmComponent } from './alert-confirm/alert-confirm.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoadMoreComponent } from './load-more/load-more.component';
import { GalleryComponent } from './gallery/gallery.component';
import { PopupRolesComponent } from './popup-roles/popup-roles.component';
import { ConfirmComponent } from './confirm/confirm.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule.forChild()
    ],
    declarations: [
        AlertConfirmComponent,
        LoadMoreComponent,
        GalleryComponent,
        PopupRolesComponent,
        ConfirmComponent,
    ],
    exports: [
        LoadMoreComponent,
        GalleryComponent,
        PopupRolesComponent,
        ConfirmComponent
    ],
    providers: [
    ]
})
export class AlertModule { }
