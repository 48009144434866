import { Injectable } from '@angular/core';
import { duration } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  convertDataSelect(arr) {
    let data = [];
    for (let index = 0; index < arr.length; index++) {
      data.push({ id: index, sortname: arr[index], itemName: arr[index] });
    }
    return data;
  }

  checkEmpty(obj) {
    for (let propName in obj) {
      if (obj[propName] instanceof Object) {
        this.checkEmpty(obj[propName]);
      } else if (!obj[propName]) {
        delete obj[propName];
      }
    }
    return obj;
  }

  convertType(type) {
    let result;
    switch (type) {
      case 1:
        result = "Help";
        break;
      case 2:
        result = "Job";
        break;
      case 3:
        result = "Housing";
        break;
      case 4:
        result = "Travering";
        break;
      case 5:
        result = "Gourmet";
        break;
      case 6:
        result = "Beauty";
        break;
    }
    return result;
  }

  convertDate(date){
    let day = new Date(date); 
    return day.getHours() + ":" + day.getMinutes() + " " + day.getDay() +"/"+ day.getMonth()+"/"+day.getFullYear();
  }

  trundertext(text, number) {
    return text.length !== 0 ? (text.substr(0, number) + (text.length > number ? '...' : '')) : '';
  }

  convertCurency(value) {
    return `¥${String(value).replace(/(.)(?=(\d{3})+$)/g, '$1,')}`;
  }
}
