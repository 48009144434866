import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CrudType } from '../../../enums/crud-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'src/app/shared/enums/utils';

@Component({
  selector: 'app-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.scss'],
  providers: [
    TranslateService
  ]
})
export class AlertConfirmComponent implements OnInit {
  @Input() alert = {
    title: Utils.TITLE_SUCCESS,
    message: 'sssssss',
    mode: 1,
    messages: [],
    textbtn: '',
    is_close: false
  };
  @Input() show_input = false;
  @Output() handleClose = new EventEmitter();
  @Output() handleSave = new EventEmitter();
  @Output() handleKeyup = new EventEmitter();
  @Output() handleSuccess = new EventEmitter();

  CrudType = CrudType;
  constructor() { }

  ngOnInit() {   
  }

  close() {
    this.handleClose.emit(this.alert);
  }

  closeModal(event){
    let target = event.target.getAttribute("class");
    if (target === "wrap-modal") this.close();
  }

  save() {
    this.handleSave.emit(this.alert);
  }

  key(value) {
    this.handleSave.emit(value);
  }

  success() {
    this.handleSuccess.emit(this.alert);
  }

}
