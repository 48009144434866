<div class="section wrap">
	<div class="error_wrap">
    	<div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-10 order-lg-first">
                	<div class="text-center">
                        <div class="error_txt">404</div>
                        <h5 class="mb-2 mb-sm-3">NOT FOUND</h5>
                        <a routerLink="/" class="btn btn-padding  btn-fill-out">{{'PAGE_404.HOME' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
