import { Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  fragment;
  isShowDropdownLang = false;
	languages = [{
    code: 'ja',
    itemName: '日本語',
    isActive: false,
  },{
    code: 'idn',
    itemName: 'Indonesia',
    isActive: false,
  },];
  lang = "";

  @Input() bglinear = false;
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService,
  ) {
    if (!localStorage.getItem('locale')) {
      localStorage.setItem('locale', 'ja');
    }
  }

  ngOnInit() {
    this.router.events.subscribe(
      params => {
          if (location.hash) {
            this.toogle(location.hash.split('#')[1]);
            return;
          } else {
            this.toogle(location.pathname.split('/')[location.pathname.split('/').length - 1]);
            return;
          }
      }
    );
		document.addEventListener("click", e => {
			let target = (e.target as HTMLTextAreaElement).getAttribute("class");
			if (!target?.includes("selected")) {
				this.isShowDropdownLang = false;
			}
		});
		this.getLanguage();
  }

	getLanguage() {
		const lan = localStorage.getItem('locale');
		for (let i = 0; i < this.languages.length; i++) {
			if (lan === this.languages[i].code) {
        this.languages[i].isActive = true;
				this.lang = this.languages[i].code;
			}
		}
		this.translateService.setDefaultLang(this.lang);
	}

  toogleBar() {
    const bar = document.getElementById('nav-mobile');
    if (bar.style.height) {
      bar.style.height = null;
      bar.style.overflow = 'hidden';
    } else {
      bar.style.height = '234px';
      bar.style.overflow = 'inherit';
    }
    this.toogle(location.hash.split('#')[1]);

  }

  toogle(value) {
    this.fragment = value;
  }

	changeLang(lang: any) {
		localStorage.setItem('locale', lang.code);
    this.languages.forEach(e => e.isActive = false);
    lang.isActive = true;
		this.lang = lang.code;
		this.translateService.setDefaultLang(lang.code);
    this.userService.changeLang();
	}

}
