<header>
  <div class="wrap">
    <h1 id="logo">LINKJAPAN<span>{{'HEADER.MANAGE_PAGE' | translate}}</span></h1>
    <div class="nav-c">
      <ng-template [ngIf]="info">
        <div class="form-user">
          <a (click)="navigatSetting()">
            <label id="avatar"
              [ngStyle]="{'background-image': info.url_avatar ? 'url(' + avatar + ')' : 'url(assets/images/default-bg.png)'}">
            </label>
            <span id="text-name"> {{info.first_name}}
              {{info.last_name}}</span>
            <span id="iconp"><img src="assets/images/icon/ic_arrow_right.svg" alt="arrow right"></span>
          </a>
        </div>    
      </ng-template>
      <div class="form-select">
        <label>
          <img src="assets/images/icon/ic_earth.png" alt="earth">
          {{lang}}
        </label>
        <app-select-dropdown [firstSelect]="true" [nameSelect]="'日本語'" (selectedOption)="setLang($event)"
          [options]="optionsLang"></app-select-dropdown>
      </div>
    </div>
  </div>
</header>
<div class="main">
  <ul class="nav" *ngIf="logined&&!hideNavbar">
    <li *ngFor="let item of menus">
      <a routerLinkActive="active" *ngIf="item?.isShow" [routerLinkActiveOptions]="{exact: item.url===configs ? true : false}"
          routerLink="{{item?.url}}" (click)="openSubs(item.id)">
        <span class="img">
          <img src="{{item.image}}" alt="{{item.title | translate}}">
          <span class="badge" *ngIf="item.notification">{{item.notification}}</span>
        </span>
        <span class="title">{{item.title | translate}}</span>
      </a>
      <!-- <div class="dropdown" *ngIf="item.subs && isOpenSubs">
        <ul>
          <li *ngFor="let sub of item.subs" (click)="handleType(sub.id)">
            <a> {{ sub.type }} </a>
          </li>
        </ul>
      </div> -->
    </li>
  </ul>
  <div class="menu-small" *ngIf="logined&&!hideNavbar" (click)="toggleMenu()">Menu</div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>