import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() isConfirmDelete = false;
  @Input() title;
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  confirmDelete() {
    this.confirm.emit(true);
  }

  cancelDelete() {
    this.cancel.emit(true);
  }
}
